<div class="breadcrumb-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="'/'">{{'home' | translate}}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{'offers' | translate}} </li>
                        <li class="breadcrumb-item active" aria-current="page" *ngIf="offerDetails?.title">{{offerDetails?.title}}</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>

<div class="page-title">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h2>{{offerDetails?.title}}</h2>
            </div>
        </div>
    </div>
</div>
<!-- section start -->
<section class="section-b-space">
    <div class="collection-wrapper">
        <div class="container">


            
            <div class="row main-banner-layout" *ngIf="offerDetails?.image_page_top || offerDetails?.image">
                <img class="banner-image" [src]="offerDetails?.image_page_top ? offersService.offersImagesUrl + offerDetails?.image_page_top : offersService.offersImagesUrl + offerDetails?.image " [alt]="offerDetails?.title">

                <!-- <div class="timer-block"  *ngIf="offerDetails?.offer_end_date && offerDetails?.show_end_date=='1' ">
                    <span class="timer-text">
                        ينتهي خلال:
                    </span>
                    <cd-timer class="clock" format="user" countdown="true" [startTime]="timeLeftTillOfferEnds">[days] {{'time.days' | translate}} [hours] {{'time.hours' | translate}} [minutes] {{'time.minutes' | translate}} [seconds] {{'time.seconds' | translate}}</cd-timer>
                </div> -->

                
                
                
                
            </div>
            <app-countdown-timer class="countdown-main" *ngIf="offerDetails?.offer_end_date && offerDetails?.show_end_date=='1' " [offerEndDate]="offerDetails?.offer_end_date"></app-countdown-timer>

            
            

            <!-- <div class="description-block" [innerHTML]="offerDetails?.description">
                
            </div> -->


            <div class="row">

                <div class="collection-content col">
                    <div class="page-main-content">
                        <div class="row">
                            <div class="col-sm-12">



                                <div class="collection-product-wrapper" id="products">

                                    <div class="product-wrapper-grid" [ngClass]="layoutView">
                                        <div class="row">
                                            <div class="col-grid-box" [ngClass]="grid" *ngFor="let product of allItems">
                                                <!-- <div class="product-box"> -->
                                                <app-product-box-one [product]="product" [currency]="productService?.Currency" [thumbnail]="false" [cartModal]="true">
                                                </app-product-box-one>
                                                <!-- </div> -->
                                            </div>
                                            <div class="col-sm-12 text-center section-b-space mt-5 no-found" *ngIf="finished &&!allItems.length">
                                                <img src="assets/images/empty-search1.png" class="img-fluid mb-4">
                                                <h3>{{'Sorry Couldn find the product' | translate}}</h3>
                                                <p></p>
                                                <a [routerLink]="['/']" class="btn btn-solid">{{'continue shopping' | translate}}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Infinite Scroll -->
                                    <div infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="300" (scrolled)="onScroll()" *ngIf="allItems.length">
                                    </div>
                                    <div class="product-infinitescroll">
                                        <div class="theme-paggination-block">
                                            <div class="container-fluid p-0">
                                                <div class="row">
                                                    <div class="text-center col-sm-12">
                                                        <div class="infinite-scrolling mt-3 mb-3 d-inline-block">
                                                            <img src="assets/images/loader.gif" *ngIf="!finished">
                                                            <div class="loading-more active" *ngIf="finished && allItems.length">{{'No More Products' | translate}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- section End -->